import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import storageService from 'services/web-storage-service';
import { SSO_DOMAIN_STORAGE_KEY } from 'shared/utils/constants';

/**
 * Resolves Auth0 organization identifier for the session.
 * Uses the organization name, but supports legacy organization id from local storage as well.
 * From URL: /o/:domainName
 * or from localstorage: SSO_DOMAIN_STORAGE_KEY
 *
 * @returns [string, function]
 * - First parameter is boolean indicating if organization is loading.
 * - Second parameter contains organization identifier, or undefined if not set.
 * - Third parameter is callback function that can be used to clear organization id on logout.
 */
const useResolveOrganizationId = (): [
  boolean,
  string | undefined,
  () => void,
] => {
  let [, searchString] = window.location.href.split('?');
  searchString = searchString?.split('#')[0];

  const searchParams = new URLSearchParams(searchString);
  const domainNameParam = searchParams.get('domainPrefix');

  const organizationLoginMatch = useMatch('/o/:domainName');
  const domainName =
    domainNameParam || organizationLoginMatch?.params.domainName;

  const storedOrganizationId =
    storageService.getLocalStorageItem(SSO_DOMAIN_STORAGE_KEY)
      ?.organizationId || undefined;

  const [organizationId, setOrganizationId] = useState<string | undefined>(
    domainName ? undefined : storedOrganizationId,
  );

  const [loading, setLoading] = useState<boolean>(
    organizationId ? false : !!domainName,
  );

  useEffect(() => {
    const loadOrganization = (domainPrefix: string | undefined) =>
      fetch(
        window.__config__.RestEndpoint +
          '/organizations/bydomain?domainName=' +
          domainPrefix,
      )
        .then(response => response.json())
        .then(data => {
          const newOrganizationId: string = data.auth0_org_id;
          storageService.setLocalStorageItem(SSO_DOMAIN_STORAGE_KEY, {
            organizationId: newOrganizationId,
            prefix: domainPrefix,
          });
          setOrganizationId(newOrganizationId);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    if (domainName) {
      loadOrganization(domainName);
    }
  }, [domainName]);

  const clearOrganization = () => {
    setOrganizationId(undefined);
    storageService.removeLocalStorageItem(SSO_DOMAIN_STORAGE_KEY);
  };

  return [loading, organizationId, clearOrganization];
};

export default useResolveOrganizationId;
