import '@midaxo/kappa-shared/src/styles/general.scss';
import '@midaxo/kappa-shared/src/styles/layout.scss';
import '@midaxo/kappa-shared/src/styles/typography.scss';
import styles from 'app.module.scss';
import { useAuth0 } from 'domain/auth/login/login-auth0';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import storageService from 'services/web-storage-service';
import {
  ANALYTICS_COOKIES_CLEARED,
  SSO_DOMAIN_STORAGE_KEY,
} from 'shared/utils/constants';
import AppLoader from './app-loader';

const AppContainer = React.lazy(() => import('./app-container'));

const AuthRouter = () => {
  const { loginWithRedirect, logout, loading } = useAuth0()!;
  const navigate = useNavigate();

  // If the user has a child app loaded and their session expires, the
  // child app will ask main-app to logout.
  useEffect(() => {
    window.addEventListener('message', event => {
      if (
        event.origin === window.location.origin &&
        event.data === 'session-expired'
      ) {
        navigate('/session-expired');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Logout = () => {
    storageService.removeLocalStorageItem(SSO_DOMAIN_STORAGE_KEY);
    if (!loading) {
      logout();
    }
    return <div className={styles['blank-page']} />;
  };

  const SessionExpired = () => {
    if (!loading) {
      logout();
    }
    return <div className={styles['blank-page']} />;
  };

  const LoggingOut = () => {
    storageService.removeSessionStorageItem(ANALYTICS_COOKIES_CLEARED);

    if (!loading) {
      loginWithRedirect();
    }
    return (
      <div className={styles['blank-page']}>
        <iframe
          title="Workspace analytics logout"
          className={styles['gdc-iframe-logout']}
          src={`${window.__config__.GooddataUrl}/logout`}
        />
        ;
      </div>
    );
  };

  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path={'/session-expired'} element={<SessionExpired />} />
        <Route path={'/logout'} element={<Logout />} />
        <Route path={'/logging-out'} element={<LoggingOut />} />
        <Route path={'*'} element={<AppContainer />} />
      </Routes>
    </Suspense>
  );
};
export default AuthRouter;
