import { Auth0Provider } from 'domain/auth/login/login-auth0';
import ReactDOM from 'react-dom';
import { NavigateFunction, HashRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AuthRouter from './auth-router';

// aws-sdk/lib-storage thinks we're on node.js
window.global = window;

import('@datadog/browser-rum').then(({ datadogRum }) => {
  datadogRum.init({
    applicationId: window.__config__.DatadogAppId,
    clientToken: window.__config__.DatadogClientToken,
    env: window.location.host,
    site: window.__config__.DatadogSite,
    service: 'pipeline-and-crm',
    sampleRate: 100,
    trackInteractions: false,
    version: window.__config__.VERSION,
  });
});

const buildPath = (path1: string, path2: string) =>
  `${path1}${path1.endsWith('/') ? '' : '/'}${path2}`;

function handleSSODomain() {
  const host = window.location.host;
  const SsoDomainName = window.__config__.SsoDomainName || '';
  const SiteUrl = window.__config__.SiteUrl || '';
  const domainPrefix =
    host.indexOf(SsoDomainName) > 0 ? host.split('.')[0] : null;

  if (domainPrefix) {
    window.location.assign(`${SiteUrl}/#/o/${domainPrefix}`);
  }
}

handleSSODomain();

ReactDOM.render(
  <RecoilRoot>
    <Router>
      <Auth0Provider
        domain={window.__config__.Auth0CustomDomainName}
        clientId={window.__config__.Auth0ClientIdApp}
        authorizationParams={{
          redirect_uri: buildPath(
            process.env.NODE_ENV === 'development'
              ? window.location.origin
              : window.__config__.SiteUrl,
            'auth/callback.html',
          ),
          audience: window.__config__.AppApiAudience,
        }}
        onRedirectCallback={(appState: any, navigate: NavigateFunction) => {
          let url = window.location.origin;
          if (appState?.returnTo) {
            url += appState.returnTo; // Return to contains the hash part of the url
            // Remove hash from url for navigate
            navigate(appState.returnTo.replace('#', ''));
          }
          window.history.pushState({}, document.title, url);
        }}
      >
        <AuthRouter />
      </Auth0Provider>
    </Router>
  </RecoilRoot>,
  document.getElementById('root'),
);
