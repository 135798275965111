import {
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import useResolveOrganizationId from '../use-resolve-organization-id';

export enum Auth0Error {
  NONE,
  // [0020] - trial has expired
  TRIAL_EXPIRED,
  // [0010] - account is closed
  ACCOUNT_CLOSED,
  // [0210] - Invalid organization configuration
  INVALID_ORGANIZATION_CONFIGURATION,
  // [0220] - SSO user not found
  SSO_USER_NOT_FOUND,
  // [0230] - User is blocked
  USER_IS_BLOCKED,
  // [0310] - Mobile app disabled
  MOBILE_APP_DISABLED,
}

export interface Cookies {
  all?: boolean;
  google?: boolean;
  necessary?: boolean;
  pendo?: boolean;
  updated?: string;
}

export interface Auth0User extends User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  'https://projectkappa.xyz/cookies': Cookies;
  'https://projectkappa.xyz/organization': string;
  'https://projectkappa.xyz/organization_name_for_pendo': string;
  'https://projectkappa.xyz/current_workspace': string;
  'https://projectkappa.xyz/workspaces': { [key: string]: string };
  'https://projectkappa.xyz/trial_end_time': string;
  'https://projectkappa.xyz/organization_created': string;
  'https://projectkappa.xyz/user_id': string;
}

interface Auth0ContextValue {
  isAuthenticated: boolean;
  loading: boolean;
  error: Auth0Error | undefined;
  user: Auth0User;
  getIdTokenClaims(): Promise<IdToken | undefined>;
  getTokenSilently(options?: GetTokenSilentlyOptions): Promise<string>;
  loginWithRedirect(options?: RedirectLoginOptions): Promise<void>;
  loginWithPopup(options?: PopupLoginOptions): Promise<void>;
  logout(options?: LogoutOptions): void;
}
interface Auth0ProviderOptions {
  children: JSX.Element;
  onRedirectCallback?: (appState: any, navigate: NavigateFunction) => void;
}
const DEFAULT_REDIRECT_CALLBACK = (appState: any, navigate: NavigateFunction) =>
  window.history.replaceState({}, document.title, window.location.pathname);

const Auth0Context = React.createContext<Auth0ContextValue | null>(null);
const useAuth0 = () => useContext(Auth0Context);

const Auth0Provider: React.FC<Auth0ProviderOptions & Auth0ClientOptions> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<Auth0User>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Auth0Error>();
  const auth0Ref = useRef<Auth0Client>();
  const auth0Client: Auth0Client = auth0Ref.current!;
  const navigate = useNavigate();

  // Get organization name from URL and map it to organization id
  const [loadingOrg, organizationId] = useResolveOrganizationId();

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = (auth0Ref.current = new Auth0Client({
          ...initOptions,
          authorizationParams: {
            ...initOptions.authorizationParams,
            organization: organizationId,
          },
        }));

        await auth0FromHook.checkSession();

        if (
          (window.location.search.includes('code=') ||
            window.location.search.includes('error=')) &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState, navigate);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser<Auth0User>();
          setUser(user);
        }
      } catch (err) {
        const error: Error = err as Error;
        const errorCode = error.message.match(/\[[0-9]{4}\]/g);
        if (errorCode) {
          // Special handling for known errors
          switch (errorCode[0]) {
            case '[0020]': // '[0020] - trial has expired'
              setError(Auth0Error.TRIAL_EXPIRED);
              break;
            case '[0010]': // '[0010] - account is closed'
              setError(Auth0Error.ACCOUNT_CLOSED);
              break;
            case '[0210]': // '[0210] - Invalid organization configuration'
              setError(Auth0Error.INVALID_ORGANIZATION_CONFIGURATION);
              break;
            case '[0220]': // '[0220] - SSO user not found'
              setError(Auth0Error.SSO_USER_NOT_FOUND);
              break;
            case '[0230]': // '[0230] - User is blocked'
              setError(Auth0Error.USER_IS_BLOCKED);
              break;
            case '[0310]': // '[0310] - Mobile app disabled'
              setError(Auth0Error.MOBILE_APP_DISABLED);
              break;
          }
          onRedirectCallback({}, navigate);
        }
      } finally {
        setLoading(false);
      }
    };
    // Start log in only when organization id is resolved, or if there was none
    if (!loadingOrg || organizationId) {
      initAuth0();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOrg, organizationId]);

  const contextValue = useMemo(() => {
    return {
      isAuthenticated,
      user: user!,
      error,
      loading,
      getIdTokenClaims: () => auth0Client.getIdTokenClaims(),
      loginWithRedirect: (o: RedirectLoginOptions) =>
        auth0Client.loginWithRedirect(o),
      loginWithPopup: (o?: PopupLoginOptions) => auth0Client.loginWithPopup(o),
      getTokenSilently: (o?: GetTokenSilentlyOptions) =>
        auth0Client.getTokenSilently(o),
      logout: (o?: LogoutOptions) => {
        import('@datadog/browser-rum').then(({ datadogRum }) => {
          datadogRum.clearUser();
          datadogRum.clearGlobalContext();
        });
        auth0Client.logout({
          ...o,
          logoutParams: {
            returnTo: window.location.origin + '/logout.html',
            ...o?.logoutParams,
          },
        });
      },
    };
  }, [auth0Client, error, isAuthenticated, loading, user]);

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};

export { Auth0Provider, useAuth0 };
