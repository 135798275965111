/**
 * Service to manage local and session storage items
 */

const withStorage = (storage: Storage) => {
  const getStorageItem = (key: string) => {
    try {
      const value = storage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (err) {
      // Storage not available
      return null;
    }
  };

  const setStorageItem = (key: string, value: unknown) => {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch {
      // Storage not available
    }
  };

  const removeStorageItem = (key: string) => {
    try {
      storage.removeItem(key);
    } catch {
      // Storage not available
    }
  };

  return {
    getStorageItem,
    setStorageItem,
    removeStorageItem,
  };
};

const getSessionStorageItem = withStorage(window.sessionStorage).getStorageItem;
const setSessionStorageItem = withStorage(window.sessionStorage).setStorageItem;
const removeSessionStorageItem = withStorage(
  window.sessionStorage,
).removeStorageItem;

const getLocalStorageItem = withStorage(window.localStorage).getStorageItem;
const setLocalStorageItem = withStorage(window.localStorage).setStorageItem;
const removeLocalStorageItem = withStorage(
  window.localStorage,
).removeStorageItem;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const onLocalStorage = (
  callback: ((key: string | null, value: unknown) => void) | null,
) => {
  window.onstorage = callback
    ? (event: StorageEvent) => {
        if (event.storageArea === window.localStorage) {
          let val = event.newValue;
          if (event.newValue) {
            try {
              val = JSON.parse(event.newValue);
            } catch (e) {
              console.error(e);
              return;
            }
          }
          callback(event.key, val);
        }
      }
    : null;
};

const storageService = {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  onLocalStorage,
};

export default storageService;
