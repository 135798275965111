import { l } from '@midaxo/kappa-shared';
import logoX from 'assets/images/midaxo-logo-x-200.png';
import classNames from 'classnames';
import React from 'react';
import styles from './app.module.scss';

interface AppLoaderProps {
  message?: string;
  init?: boolean;
}

const AppLoader: React.FC<AppLoaderProps> = ({ message, init }) => {
  const classes = classNames({
    [styles['loader-container']]: true,
    [styles['init-loader']]: init,
    [styles['end-loader']]: !init,
  });

  return (
    <div className={classes}>
      <div className={styles['content']}>
        <img src={logoX} alt={l`Midaxo Logo`} />
        <p className={styles['product-name']}>{l`Midaxo`}</p>
        <p className={styles['message']}>{message}</p>
      </div>
    </div>
  );
};

export default AppLoader;
